@import "../../styles/app_variables";

.pac-container {
	z-index: 99999 !important;
	font-family: $font-family-text;
	border-radius: $border-radius;
	margin-top: -1px;

	.pac-item {
		padding: 2px 4px;
		&:last-of-type {
			padding-bottom: 0px;
		}
	}
}

fieldset.PrivateNotchedOutline-root-208 {
	top: -5px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	padding: 0 8px;
	overflow: hidden;
	position: absolute;
	border-style: solid;
	border-width: 1px;
	border-radius: inherit;
	pointer-events: none;

	legend.PrivateNotchedOutline-legend-209 {
		padding: 0;
		text-align: left;
		transition: width 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		line-height: 11px;
	}
}
