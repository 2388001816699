@import "app_variables";

.text-underline {
	text-decoration: underline;
}

.text-black {
	color: $black;
}

.text-gray {
	color: $gray;
}

.text-light-gray {
	color: rgba(0, 0, 0, 0.54);
}

.text-md {
	font-size: $font-size-md !important;

	&.text-md-inner * {
		font-size: $font-size-md !important;
	}
}

.text-sm {
	font-size: $font-size-sm;
}

.text-xs {
	font-size: $font-size-xs !important;
}

.text-xxs {
	font-size: $font-size-xxs !important;
}

.text-xxxs {
	font-size: $font-size-xxxs !important;
}

.text-shrink-xs {
	@include style-layout-xs() {
		font-size: 80%;
	}
}

@include style-layout-tablet-desktop() {
	.text-center-desktop {
		text-align: center;
	}
}

.MuiTypography-root {
	font-size: $font-size-md !important;
}

ul {
	list-style-type: square;
}

.ql-editor {
	ul {
		// list-style-type: square !important;
		// display: none !important;
	}
}

.text-decoration-none * {
	text-decoration: none;
}

.title-with-border {
	color: $dark;
	font-weight: bold;
	text-transform: capitalize;
	padding-bottom: 0.6rem;
	margin-bottom: 1.6rem;
	border-bottom: solid 1px $border-color;
}
