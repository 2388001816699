@import "app_variables";

$row-vertical-padding: 7px;
$row-extra-vertical-padding: 15px;

table.table {
	table-layout: fixed;
	border-collapse: collapse;

	thead {
		th {
			text-transform: uppercase;
			font-weight: bold;
			border-top: none 0px !important;
			border-bottom: 2px solid $gray;
			padding-right: 0px;
			padding-left: 0px;
		}
	}

	tr,
	td {
		border: none 0px !important;
	}

	tbody {
		tr {
			&:first-of-type {
				td {
					padding-top: 20px;
				}
			}
		}
		td {
			padding: $row-vertical-padding 0px;
		}
	}

	&.alternate-table {
		thead {
			th {
				color: $gray;
				border-bottom: 1px solid $border-color;
			}
		}
		tbody {
			tr {
				&:first-of-type {
					td {
						padding-top: $row-extra-vertical-padding;
						&.actions-cell {
							padding-top: $row-vertical-padding;
						}
					}
				}
				td {
					vertical-align: middle;
					&:not(.actions-cell) {
						padding: $row-extra-vertical-padding 0px;
						padding-right: 20px;
					}
					&.actions-cell {
						padding-top: 10px !important;
						padding-bottom: 10px !important;
					}
				}
				border-bottom: solid 1px $border-color !important;
			}
		}
	}
}

.item-mobile-row {
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid $border-color;
	&:last-of-type {
		border-bottom: none 0px;
	}
	&.alternate-row-style {
		border-bottom-color: $border-color;
		&:last-of-type {
			border-bottom: solid 1px $border-color;
			margin-bottom: 20px;
		}
		&.first-row {
			border-top: solid 1px $border-color;
		}
	}
}

.featured-item {
	@include style-layout-lg-up() {
		&:last-of-type {
			margin-bottom: 0px !important;
		}
	}

	@include style-layout-mobile() {
		.mss-image-view {
			width: 200px !important;
			height: 150px !important;
		}
	}

	@include style-layout-xs() {
		.mss-image-view {
			width: 100% !important;
			height: 280px !important;
		}
	}
}
