@import "./overrides";
// bootstrap overrides and a few more: ***********************************
// colors:
$primary: #eb7635 !default;
$lightOrange: #f7e6d5;
$black: #212a30;

$blue: #2c4479 !default;
$red: #cd2822;
$orange: $primary;
$yellow: #dd9f00;
$green: #1e5435;

$primary-light: #ebc080 !default;
$primary-lightest: lighten($primary-light, 25%) !default;
$marker-bg-color: #52bfff !default;
$btn-hover-color: darken($primary, 15%) !default;
$btn-hover-border-color: darken($primary, 20%) !default;
$bg-black: $black !default;
$dark: rgba(14, 11, 9, 0.95) !default; // #0E0B09
$appLightBlack: #181818;
$dark-opac: rgba(14, 11, 9, 0.85);
$gray: #747980 !default;
$footer-gray: $gray !default;
$light: #ffffff;
$white: #ffffff;
$white2: rgba(116, 121, 128, 0.05); // TODO: hex
$white-overlay: rgba(255, 255, 255, 0.95);
$dark-transparent: rgba(0, 0, 0, 0.6);
$dark-transparent-hover: rgba(0, 0, 0, 0.5);
$light-outline-color: rgba(238, 238, 238, 0.1);
$ui-box-shadow: 2px 2px 0px var(--color-contrast-bg);
$psLogoColor: #87cbe7;
$logoCollapseHeight: 82px !default;
$arrowUrl: "../assets/icons/iconmonstr-arrow-71-24.png" !default;

$gray-100: #f8f9fa !default;
$gray-150: #f2f5fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

//$secondary: $gray-600 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$material-error: $danger;
//$light: $gray-100 !default;
//$dark: $gray-800 !default;

$link-color: $primary;
$link-hover-color: $gray-600; // $black;
$link-alt-hover-color: $primary;
$border-color: rgba(222, 222, 222, 1); // $gray-500; // #eeeeee;

// typography
$font-family-text: "Montserrat", sans-serif;
$font-family-sans-serif: $font-family-text;
// 242A2E
// $headings-color
//$body-bg: $white !default;
$body-color: #242a2e;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 600;
$link-decoration: none;
$link-hover-decoration: none;
$input-placeholder-color: $gray-500 !default;
// $font-family-base: $font-family-text;
// http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
// $font-size-base: calc(12px + 0.1vw);
$font-size-base: 1rem; // 16px
$font-size-md: 0.8rem; // 14px = 0.875rem
$font-size-md-2: 0.875rem;
$font-size-sm: 0.73125rem;
// $font-size-small: 0.7875rem;
$font-size-xs: 0.73125rem;
$font-size-xxs: 0.65rem;
$font-size-xxxs: 0.542rem;
$h2-font-size: 1.17rem;
$h3-font-size: 0.99rem;
$h4-font-size: 0.8rem;
$h5-font-size: 0.765rem;

//navbar
$navbar-height: auto;
$navbar-padding-y: 0px;
$nav-link-padding-y: 0;
// $navbar-brand-padding-y
$navbar-logo-width-mobile: 85px !default;
$navbar-logo-height-mobile: 80px !default;
$navbar-logo-auth-left: 18px !default;
$navbar-logo-auth-top: 18px !default;

// $grid-gutter-width: 50px;
$form-grid-gutter-width: 0px; // was 10

// form controls
$border-radius: 20px;
$input-border-radius: 10px;
$input-btn-font-size: $font-size-sm; //$font-size-base;
$input-font-size: $font-size-sm; // $font-size-base; //16px; // needs to be 16px so iphone doesn't focus in
$btn-font-weight: 500;
$btn-padding-y: 10px;
$btn-padding-x: 15px;

$grid-breakpoints: (
	xs: 0,
	sm: 575px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
) !default;

$container-max-widths: (
	sm: 576px,
	md: 769px,
	lg: 992px,
	xl: 1350px,
) !default;

// end bootstrap override: ***********************************

$checkmarkUrl: "../assets/icons/iconmonstr-check-mark-1-24.png" !default;

a.light-link {
	&:hover {
		color: $link-alt-hover-color;
	}
	&.active {
		color: $primary;
	}
}

// grid utitlities
@mixin style-layout-mobile() {
	@media (max-width: map-get($container-max-widths, "md")) {
		@content;
	}
}

@mixin style-layout-md-down() {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin style-layout-xs() {
	@media (max-width: map-get($container-max-widths, "sm")) {
		@content;
	}
}

@mixin style-layout-xxs() {
	@media (max-width: 375px) {
		@content;
	}
}

@mixin style-layout-desktop() {
	@media (min-width: 993px) {
		@content;
	}
}

@mixin style-layout-tablet-desktop() {
	@media (min-width: map-get($grid-breakpoints, "sm")) {
		//@media (min-width: 576px) {
		@content;
	}
}

@mixin style-layout-md-up() {
	@media (min-width: map-get($container-max-widths, "md")) {
		@content;
	}
}

@mixin style-layout-tablet-mobile() {
	@media (max-width: map-get($grid-breakpoints, "lg")) {
		//@media (max-width: 992px) {
		@content;
	}
}

@mixin style-layout-lg-up() {
	@media (min-width: 993px) {
		@content;
	}
}

@mixin style-layout-lg-down() {
	@media (max-width: map-get($grid-breakpoints, "lg")) {
		//@media (max-width: 992px) {
		@content;
	}
}

@mixin style-layout-xl-up() {
	@media (min-width: 1201px) {
		@content;
	}
}

@mixin style-layout-xl-down() {
	@media (max-width: map-get($grid-breakpoints, "xl")) {
		//@media (max-width: 1200px) {
		@content;
	}
}

// @include media-breakpoint-up(sm) {
// 	// Styles
//   }

// Mixin to prefix several properties at once
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {
	@each $property, $value in $declarations {
		@each $prefix in $prefixes {
			#{'-' + $prefix + '-' + $property}: $value;
		}

		// Output standard non-prefixed declaration
		#{$property}: $value;
	}
}

@mixin transform($props...) {
	@include cross-browser-property(transform, $props);
}

@mixin cross-browser-property($prop-name, $value...) {
	@each $browser in "moz", "webkit", "o", "ms" {
		-#{$browser}-#{$prop-name}: $value;
	}
	$prop-name: $value;
}

@mixin box-shadow() {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
		rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
}

@mixin box-shadow-light() {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px 0px;
}

@mixin showLoader() {
	position: relative;
	overflow: hidden;
	transform-style: preserve-3d;
	&:before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		top: 52%;
		left: 83%;
		width: 28px;
		height: 28px;
		margin-top: -15px;
		margin-left: -15px;
		border-radius: 50%;
		border: 1px solid #ccc;
		border-top-color: $black;
		animation: spinner 0.6s linear infinite;
	}
}

@mixin rotate() {
	animation: spinInPlace 1.8s linear infinite;
	transform-origin: 50% 50%;
}

@keyframes spinInPlace {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

@keyframes move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 28px 0;
	}
}

@keyframes move-down {
	0% {
		background-position: center top;
	}
	100% {
		background-position: center bottom;
	}
}

@keyframes blink {
	0% {
		opacity: 0;
	}

	30%,
	60% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

$os-scrollbar-radius: 2px;
@mixin os-scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
	::-webkit-scrollbar {
		width: $size;
		height: $size;
	}

	::-webkit-scrollbar:hover {
		// background: $primary-lightest;
	}

	::-webkit-scrollbar-track {
		-webkit-border-radius: $os-scrollbar-radius;
		border-radius: $os-scrollbar-radius;
		// background: rgba(0, 0, 0, 0.1);
		background: $background-color;
	}

	::-webkit-scrollbar-thumb {
		-webkit-border-radius: $os-scrollbar-radius;
		border-radius: $os-scrollbar-radius;
		// background: rgba(0, 0, 0, 0.2);
		background: $foreground-color;
	}

	::-webkit-scrollbar-thumb:hover {
		// background: rgba(0, 0, 0, 0.4);
		background-color: $orange;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		// background-color: rgba(0, 0, 0, 0.05);
		// background-color: $gray;
	}

	// For Internet Explorer
	& {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}

// @mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
// 	// For Google Chrome
// 	&::-webkit-scrollbar {
// 		width: $size;
// 		height: $size;
// 	}

// 	// &::-webkit-scrollbar-thumb {
// 	// 	background: $foreground-color;
// 	// }

// 	// &::-webkit-scrollbar-track {
// 	// 	background: $background-color;
// 	// }

// 	// // For Internet Explorer
// 	// & {
// 	// 	scrollbar-face-color: $foreground-color;
// 	// 	scrollbar-track-color: $background-color;
// 	// }
// }

@mixin placeholder() {
	::-webkit-input-placeholder {
		@content;
	}
	::-moz-placeholder {
		@content;
	}
	:-ms-input-placeholder {
		@content;
	}
	::placeholder {
		@content;
	}
}

@mixin progress-bar-background() {
	width: 100%;
	border: 1px solid #fff;
	background-image: repeating-linear-gradient(-45deg, $gray-100, $gray-100 11px, #fff 10px, #fff 20px);
	background-size: 28px 28px;
	animation: move 0.5s linear infinite;
	text-transform: uppercase;

	.loading-text {
		font-family: $font-family-sans-serif !important;
		font-size: 12px !important;
		span {
			animation-name: blink;
			animation-duration: 1.2s;
			animation-iteration-count: infinite;
			animation-fill-mode: both;
			/* animation-delay: 2s; */

			&:nth-of-type(2n) {
				animation-delay: 200ms;
			}
			&:nth-of-type(3n) {
				animation-delay: 400ms;
			}
		}
	}
}

@mixin triangle-left {
	content: "";
	position: absolute;
	width: 0px;
	height: 0px;
	top: 11px;
	left: 0px;
	box-sizing: border-box;
	border: 0.5em solid $gray-800;
	border-color: transparent transparent #ffffff #ffffff; // triangle bg color
	transform-origin: 0 0;
	transform: rotate(45deg);
	box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1); // shadow thickness
}

@mixin triangle-top($size: 0.5em, $bgColor: #ffffff) {
	content: "";
	position: absolute;
	width: 0px;
	height: 0px;
	top: 0px;
	box-sizing: border-box;
	border: $size solid $gray-800;
	border-color: transparent transparent $bgColor $bgColor; // triangle bg color
	transform-origin: 0 0;
	transform: rotate(135deg);
	box-shadow: -1px 1px 0px 0px $gray-400;
}

@mixin triangle-top-shadow($size: 0.5em, $bgColor: #ffffff) {
	@include triangle-top($size, $bgColor);
	box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1); // shadow thickness
}

@mixin desktop-horizontal-padding() {
	@include style-layout-desktop() {
		padding-left: 0.5rem !important;
		padding-right: 0.5rem !important;
	}
}

// must set width
@mixin text-overflow() {
	display: inline-block;
	min-width: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin divider() {
	content: "";
	position: absolute;
	height: 1px;
	left: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.12);
}

@mixin top-divider() {
	&:before {
		@include divider();
		top: -1px;
		@content;
	}
}

@mixin bottom-divider() {
	&:after {
		@include divider();
		bottom: -1px;
		@content;
	}
}

@mixin arrow-up($color) {
	display: inline-block;
	border-top: 0px none;
	border-left: 0.3rem solid transparent;
	border-bottom: 0.5rem solid $color;
	border-right: 0.3rem solid transparent;
	margin-right: 0.31rem;
	margin-bottom: 0px;
}

@mixin arrow-down($color) {
	display: inline-block;
	border-top: 0.5rem solid $color;
	border-left: 0.3rem solid transparent;
	border-bottom: 0px none;
	border-right: 0.3rem solid transparent;
	margin-right: 0.31rem;
	margin-bottom: -1px;
}

// @import "~bootstrap/scss/_functions";
// @import "~bootstrap/scss/_variables";
@import "ios-viewport-fix";

@mixin headerImg() {
	background-color: var(--color-contrast-bg);

	// laCountyFiremen
	//background-image: url("../assets/HeroSection2.png");
	background-repeat: no-repeat;
	/* Create the parallax scrolling effect */
	background-attachment: fixed;
	background-size: cover;

	@include style-layout-desktop() {
		background-position: center -15vh;
		//background-position-y: -100px;
	}

	// ipad pro
	@media (max-width: 1024px) {
		background-size: 150% auto;
		background-position: center 0vh !important;
	}

	@include style-layout-mobile() {
		background-size: 150% auto;
		background-position: center -10vh !important;
	}

	@include style-layout-xs() {
		background-size: 200% auto;
		background-position: center top !important;
	}

	@include style-layout-xxs() {
		background-size: 250% auto !important;
	}

	// long mobile devices
	@media screen and (orientation: portrait) and (min-height: 1000px) {
		background-size: 300% auto !important;
	}
}

@mixin filter($value) {
	-webkit-filter: $value;
	filter: $value;
}

@keyframes filterSepiaToColor {
	0% {
		// @include filter(sepia(100%));
		@include filter(brightness(500%));
		// filter: brightness(500%);
	}
	100% {
		// @include filter(none);
		@include filter(brightness(50%));
		// filter: drop-shadow(8px 8px 10px green);
	}
}

@keyframes filterColorToSepia {
	0% {
		// @include filter(sepia(100%));
		// @include filter(brightness(80%));
		// filter: brightness(500%);
		@include filter(brightness(50%));
	}
	100% {
		// @include filter(none);
		@include filter(blur(2px) grayscale(70%));
		// @include filter(grayscale(70%));
		// filter: drop-shadow(8px 8px 10px green);
	}
}

@mixin editor-content-styles() {
	a {
		color: $link-color;
		text-decoration: none !important;
		cursor: pointer;
		&:hover {
			color: $link-hover-color;
		}
	}
	.ql-size-small {
		font-size: 14px;
	}
	ol {
		li {
			margin-bottom: 5px;
		}
	}
	ul {
		margin: 0px !important;
		padding: 0px !important;
		list-style-type: none !important;
		li {
			&[data-list="bullet"] {
				list-style-type: none !important;
				.ql-ui {
					&::before {
						display: none !important;
					}
				}
				&::before {
					// content: "\2022"; circle
					content: "\25AA";
					color: $primary !important;
					font-weight: bold;
					display: inline-block;
					width: 1em;
					// margin-left: -1em;
				}
			}
		}
	}
}

// @include editor-content-styles();
// TODO: ch159 apply for dynamic content containers
@mixin iconmonstr() {
	display: inline-block;
	font: normal normal normal 24px/1 "iconmonstr-iconic-font";
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin h1() {
	font-size: 2.4rem; // 64px;
	line-height: 3.2rem;
	font-weight: 700;
	@include style-layout-mobile() {
		font-size: 2rem;
		line-height: 2.55rem;
	}
}

@mixin h2() {
	font-size: 1.9rem; // 36px
	line-height: 3.063rem;
	font-weight: $headings-font-weight;
	@include style-layout-mobile() {
		font-size: 1.7rem;
	}
}

@mixin h1Highlighted() {
	font-size: 2.5rem;
	@include style-layout-mobile() {
		font-size: 2rem !important;
	}
}

@mixin site-icon {
	max-width: 62px;
	max-height: 60px;
	//@include style-layout-mobile() {
	//	max-width: 40px;
	//	max-height: 40px;
	//}
}

@mixin input-override() {
	border-radius: $input-border-radius !important;
	color: $black !important;
	// background-color: red !important;
	margin-top: 0px !important;
	border: solid 1px $border-color !important;
	transition: border-color 0.2s ease-in-out;
	//border-top: solid 1px $border-color !important;
	//border-right: solid 1px $border-color !important;
	//border-left: solid 1px $border-color !important;
	// border-bottom: 1px solid $gray;
	padding: 18.5px 14px !important;
	line-height: 19px !important;
	min-height: 61px !important;
	//-webkit-transition: border 300ms ease-in-out;
	//-moz-transition: border 300ms ease-in-out;
	//-o-transition: border 300ms ease-in-out;
	//transition: border 300ms ease-in-out;
	&:hover {
		//border-top: solid 1px $gray !important;
		//border-right: solid 1px $gray !important;
		//border-left: solid 1px $gray !important;
		border-color: $gray-700 !important;
	}
}

$form-label-font-weight: 500;
@mixin label-override() {
	color: $black !important;
	font-size: 0.8rem !important;
	font-weight: $form-label-font-weight !important;
	font-family: $headings-font-family !important;
	margin-bottom: 8px;
	cursor: default;
}

@mixin text-selection-color() {
	&::-moz-selection {
		background: $primary-lightest !important;
	}
	&::selection {
		background: $primary-lightest !important;
	}
}

@mixin equalWidthItemFlex() {
	flex-grow: 1;
	flex-basis: 0;
}

@mixin scaleEffect() {
	-webkit-filter: none;
	filter: none;
	-webkit-transform: scale(1.09, 1.09);
	transform: scale(1.09, 1.09);
	-webkit-transition: all 0.7s ease;
	-moz-transition: all 0.7s ease;
	-o-transition: all 0.7s ease;
	-ms-transition: all 0.7s ease;
	transition: all 0.7s ease;
}

@mixin scaleInOnHover() {
	will-change: transform;
	overflow: hidden;
	&:hover {
		@include scaleEffect();
	}
}

.border-radius-card {
	border-radius: $border-radius;
}
